import 'reflect-metadata'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { ToastContainer } from 'react-toastify'
import { Hydrate, QueryClientProvider } from 'react-query'
import { useState } from 'react'

import { AuthenticateProvider } from '@hooks/useAuthentication'
import { ModalProvider } from '@hooks/contexts/ModalContext/ModalContext'
import { ThemeProvider } from '@hooks/contexts/ThemeContext/ThemeContext'
import { client, queryClient } from '@client/init'
import { AppClientProvider } from '@hooks/useClient'

import '@styles/globals.css'
import '@styles/tiptap.css'
import '@styles/toggle.css'
import '@styles/swiper.css'
import '@styles/toastify.css'
import '@styles/consent.css'
import '@styles/rcPagiante.css'
import '@styles/drawer.css'
import { DrawerProvider } from '@hooks/contexts/DrawerContext/DrawerContext'
import { CategoriesProvider } from '@hooks/useCategories'
import { InvalidConsent } from '@components/InvalidConsent'
import { ChallengeModalProvider } from '@hooks/contexts/ChallengeModalContext/ChallengeModalContext'
import ChallengeSnapshot from '@components/Challenge/components/ChallengeSnapshot/ChallengeSnapshot'
import { useFacebookPixel } from '@hooks/useFacebookPixel'
import { useGoogleAnalytics } from '@hooks/useGoogleAnalytics'
import { useTiktok } from '@hooks/useTiktok'
import { UserManagement } from '@components/UserManagement'
import { TriggleLimitAuthen } from '@components/TriggleLimitAuthen'
import { BanModal } from '@features/authentication/components/BanModal'
import { BanModalParams } from '@features/authentication/components/BanModal/interface'
import { TriggerMaintenance } from '@components/TriggerMaintenance'
import { PromotionSaleSnapshot } from '@components/PromotionSale/PromotionSaleSnapShort'
import { ConsentTypeEnum } from '@interfaces/ConsentTypeEnum'
import { IntervalProvider } from '@hooks/common/useInterval'

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const [invalidConsent, setInvalidConsent] = useState<
    ConsentTypeEnum | undefined
  >(undefined)
  const [banModalParams, setBanModalParams] = useState<BanModalParams>({
    isBan: false,
    punishment: undefined,
  })
  client.setupConsent(setInvalidConsent)
  client.setupBanModal(setBanModalParams)
  useFacebookPixel()
  useGoogleAnalytics()
  useTiktok()

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <AppClientProvider client={client}>
          <AuthenticateProvider>
            <ThemeProvider>
              <CategoriesProvider>
                <ChallengeModalProvider>
                  <ModalProvider>
                    <DrawerProvider>
                      <IntervalProvider>
                        <Head>
                          <meta
                            name='viewport'
                            content='width=device-width, height=device-height, initial-scale=1.0'
                          />
                          <title>
                            MaReads มารี้ด | อ่านจุใจ (นิยายออนไลน์ นิยายแปล
                            การ์ตูน อีบุ๊ก) ฟินกว่าใคร
                            จักรวาลใหม่ของนักอ่านนักเขียน
                          </title>
                        </Head>
                        <InvalidConsent
                          invalidConsent={invalidConsent}
                          updateInvalidConsent={setInvalidConsent}
                          isMaintenance={pageProps?.isMaintenance}
                        />
                        <BanModal
                          banModalParams={banModalParams}
                          setBanModalParams={setBanModalParams}
                        />
                        <ToastContainer
                          autoClose={5000}
                          hideProgressBar
                          closeOnClick
                          rtl={false}
                          position='top-center'
                          pauseOnHover
                          theme='colored'
                        />
                        <ChallengeSnapshot />
                        <PromotionSaleSnapshot />
                        <UserManagement />
                        <TriggleLimitAuthen />
                        <TriggerMaintenance />
                        <Component {...pageProps} />
                      </IntervalProvider>
                    </DrawerProvider>
                  </ModalProvider>
                </ChallengeModalProvider>
              </CategoriesProvider>
            </ThemeProvider>
          </AuthenticateProvider>
        </AppClientProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}

export default MyApp
