import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  ReactNode,
  useState,
} from 'react'

interface IntervalContextType {
  tick: number
  clearIntervalFunction: () => void
}

const IntervalContext = createContext<IntervalContextType | null>(null)

export const IntervalProvider: React.FC<{
  children: ReactNode
  interval?: number
}> = ({ children, interval = 1000 }) => {
  const intervalId = useRef<NodeJS.Timeout | null>(null)
  const [tick, setTick] = useState(0)

  const clearIntervalFunction = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
      intervalId.current = null
      setTick(0)
    }
  }

  useEffect(() => {
    clearIntervalFunction()

    intervalId.current = setInterval(() => {
      setTick(prev => prev + 1)
    }, interval)
  }, [interval])

  useEffect(() => {
    return () => {
      clearIntervalFunction()
    }
  }, [])

  return (
    <IntervalContext.Provider value={{ tick, clearIntervalFunction }}>
      {children}
    </IntervalContext.Provider>
  )
}

export const useInterval = (): IntervalContextType => {
  const context = useContext(IntervalContext)
  if (!context) {
    console.warn('useInterval was used outside of an IntervalProvider.')
    return {
      tick: 0,
      clearIntervalFunction: () => {
        console.warn('IntervalProvider is missing.')
      },
    }
  }
  return context
}
