import { Expose, Transform, Type } from 'class-transformer'
import { CategoryType } from '@models/category/CategoryType'

import { TagType } from '@models/story/TagType'
import { BookEnum } from '@interfaces/BookEnum'
import { PenNameType } from '@models/penName/PenNameType'
import { WritingEnum } from '@interfaces/WritingEnum'
import { BookRatingType } from '@models/book/BookRatingType'
import { BookBuyAllChapterPromotion } from '@models/book/BookBuyAllChapterPromotion'
import { BookReadFreePromotion } from '@models/book/BookReadFreePromotion'
import { BookBadge } from '@models/book/BookBadge'

export class SearchRecommendType {
  @Expose()
  id: number

  @Expose()
  writer: string

  @Expose()
  coverImgPath: string

  @Expose()
  coverResizeImgPath: string

  @Expose()
  title: string

  @Expose()
  cover: string

  @Expose()
  penName: PenNameType

  @Expose()
  @Type(() => TagType)
  tags: TagType[]

  @Expose()
  category: CategoryType

  @Expose()
  viewCount: number

  @Expose()
  likeCount: number

  @Expose()
  commentCount: number

  @Expose({ name: 'publishedChapterCount' })
  chapterCount: number

  @Expose()
  isEnded: boolean

  @Expose()
  bookType: BookEnum

  @Expose()
  @Transform(({ value }) => value ?? false)
  isLiked: boolean

  @Expose()
  writingType: WritingEnum

  @Expose()
  publishedAt: string

  @Expose()
  @Type(() => BookRatingType)
  rating: BookRatingType

  @Expose() isTranslated: boolean

  @Expose() isEpub: boolean

  @Expose() isPdf: boolean

  @Expose() hasSalePromotions?: boolean

  @Expose()
  @Type(() => BookBuyAllChapterPromotion)
  buyAllChaptersPromotion?: BookBuyAllChapterPromotion

  @Expose()
  @Type(() => BookReadFreePromotion)
  readFreePromotion?: BookReadFreePromotion

  @Expose()
  @Type(() => BookBadge)
  bookBadges?: BookBadge[]
}
